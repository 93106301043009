import { MetricsPanelCtrl } from 'grafana/app/plugins/sdk';
import defaultsDeep from 'lodash/defaultsDeep';
import _ from 'lodash';

import { getLocationSrv } from '@grafana/runtime';

export default class SimpleCtrl extends MetricsPanelCtrl {
  static templateUrl = 'partials/module.html';

  panelDefaults = {
    text: 'Operator ID',
    email: 'userEmail',
    operatorID: 'operatorID',
  };
  userEmail = '';

  /** @ngInject */
  constructor($scope, $injector, private contextSrv, public templateSrv) {
    super($scope, $injector);
    defaultsDeep(this.panel, this.panelDefaults);

    const variable = this.getVariable(this.panel.email);

    if (variable) {
      this.userEmail = this.contextSrv.user.email;
      this.updateVarable(this.panel.email, this.userEmail);
    }

    // Get results directly as DataFrames
    (this as any).dataFormat = 'series';

    this.events.on('init-edit-mode', this.onInitEditMode.bind(this));
    this.events.on('render', this.onRender.bind(this));
    this.events.on('data-error', this.onDataError.bind(this));
    this.events.on('data-received', this.onDataReceived.bind(this));
    this.events.on('data-snapshot-load', this.onDataSnapshotLoad.bind(this));
  }
  getVariable(varname: string): any {
    const v = _.find(this.templateSrv.getVariables(), (check: { name: string }) => {
      return check.name === varname;
    });
    return v;
  }

  onDataSnapshotLoad(snapshotData: any) {
    this.onDataReceived(snapshotData);
  }

  onDataReceived(dataList: any) {
    let operatorID = '0';
    if (dataList.length > 0 && dataList[0].rows && dataList[0].rows.length > 0) {
      operatorID = dataList[0].rows[0][0];
    } else {
      console.log('Cannot find operator id.');
    }
    this.updateVarable(this.panel.email, this.userEmail);
    this.updateVarable(this.panel.operatorID, operatorID);
  }
  onInitEditMode() {
    this.addEditorTab('Options', `public/plugins/${this.pluginId}/partials/options.html`, 2);
  }

  onRender() {
    return;
  }

  onDataError(err: any) {
    console.log('onDataError', err);
  }

  updateVarable(varname: string, value: string) {
    if (!varname) {
      return;
    }
    const name = 'var-' + varname;
    getLocationSrv().update({
      partial: true,
      query: {
        [name]: value,
      },
      replace: true,
    });
  }
}

export { SimpleCtrl as PanelCtrl };
